html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: "BMWTypeNext";
  src: url("./fonts/BMWTypeNextLatin-Bold.otf");
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "BMWTypeNext";
  src: url("./fonts/BMWTypeNextLatin-Regular.otf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "BMWTypeNext";
  src: url("./fonts/BMWTypeNextLatin-Thin.otf");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}
